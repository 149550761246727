import React from 'react';

import { PAGES } from '../../../utils/utils';
import Liability from '../../../components/en/expertise/liability';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';

const LiabilityPage = () => (
  <Layout lang="en" page={PAGES.COMPETENCES_RESPONSABILITE}>
    <SEO title="Liability" lang="en" />
    <Liability />
  </Layout>
);

export default LiabilityPage;
