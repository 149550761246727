import React from 'react';

import ExpertiseContainer, { EXPERTISE, generateBreadcrumb } from './container';

const Liability = () => {
  return (
    <ExpertiseContainer currentOption={EXPERTISE.LIABILITY}>
      <h1>Liability</h1>

      {generateBreadcrumb('liability')}

      <hr />

      {/* Description */}

      <div id="texteImg">
        <img
          className="responsabilite"
          src="/img/competences_sprite.jpg"
          alt="Liability"
        />
      </div>

      <p>
        Our lawyers handle a wide variety of{' '}
        <span className="accent">bodily injury</span> claims.{' '}
        <span className="accent2">Injuries</span> often have a long term impact
        on the life quality of our clients and that is why we make every effort
        to search for the best outcome for your injury{' '}
        <span className="accent2">compensation</span>.
      </p>
    </ExpertiseContainer>
  );
};

Liability.propTypes = {};

export default Liability;
